<template>
  <n-space style="padding: 24px;">
    <Header :username="bernhard"/>
  </n-space> 

  <n-grid x-gap="12" :cols="3" y-gap="100"  v-if="user_email"> 
    <n-grid-item :offset="1">  
        <n-text type="info"  v-if="!success.length">Hello, Please fill the form in order to refresh your finance chart:</n-text>
    </n-grid-item>
    <n-grid-item :offset="1"> 
        <form @submit="checkForm" method="post">

            <n-space vertical :size="12">
              <n-alert  v-if="success.length"  title="Success Text" type="success">
                Thank you for the form submission ! You will receive an email notification once the request has been handled, this usually takes from 5 to 30 minutes.
              </n-alert>
              <n-alert  v-if="errors.length"  title="Error Text" type="error"> Please check if the information in the form is correct</n-alert>
            </n-space>

            <n-space vertical   v-if="!success.length">
               <n-input v-model:value="user_email" :default-value="user_email" type="text" />
              <n-button type="primary" attr-type="submit" size="large">Submit your request for a fresh finance report</n-button>
            </n-space>
        </form>
    </n-grid-item>
  </n-grid>
 
</template>

<script>
import Header from './components/Header.vue'
import { getCurrentInstance, onMounted, ref } from 'vue';

export default {
  name: 'App',
  components: {
    Header
  },
  setup(){
   const internalInstance = getCurrentInstance()
   const user_email = ref('')  


  onMounted(() => {
      user_email.value = internalInstance.appContext.config.globalProperties.$accountinfo_global_email
      setTimeout(function(){ user_email.value = internalInstance.appContext.config.globalProperties.$accountinfo_global_email; }, 3000);
    })

      return {user_email}


  },
  data(){
    return{
        errors: [],
        success: [],
        posts:{
            user_email:null
        }
    }
  },    
  methods:{
     checkForm: function (e) {
      this.errors = [];


      console.warn(this.errors)
      if (!this.errors.length) {
        e.preventDefault();
        return this.postData(e)
      }
      e.preventDefault();

    },
  postData(e){
        console.warn(this.posts)
        this.posts.email_recipients = this.user_email
        console.warn(this.posts)
        this.axios.post('https://prod-103.westeurope.logic.azure.com:443/workflows/1792725affb040199ba210f2c1892ae4/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=TDOyaxDF5z13gcViL_O-ePFyWySovOV8JNNfc1Wsdv0',this.posts)
        .then((result)=>{
            console.warn(result)
            if(result.data.success == "True"){
              this.success.push('success');
            }else{
              this.errors['backend_error']=1;
            }
        })
        e.preventDefault();
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.light-green {
  height: 108px;
  background-color: rgba(0, 128, 0, 0.12);
}
.green {
  height: 108px;
  background-color: rgba(0, 128, 0, 0.24);
}
</style>
