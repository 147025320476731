export default {
    install: (app) => {

        const msal = require("@azure/msal-browser");
        const msalConfig = {
            auth: {
                clientId: 'fe3c97ce-c643-4a6f-8c4f-88ce199f2df8'
            }
        };
        var loginRequest = {
            scopes: ["user.read", "mail.send"] // optional Array<string>
        };
        
        const msalInstance = new msal.PublicClientApplication(msalConfig);
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
          msalInstance.setActiveAccount(accounts[0]);
        }
       
        const EventType = ''
        const loginUser = async () => {
            
            console.log(loginRequest);
            msalInstance.addEventCallback((event) => {
                // set active account after redirect
                if (event.eventType && event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
                  const account = event.payload.account;
                  msalInstance.setActiveAccount(account);
                }
              }, error=>{
                console.log('error', error);
              });
        
        
                // handle auth redired/do all initial setup for msal
              let accountinfo = await msalInstance.handleRedirectPromise().then(authResult=>{
                  // Check if user signed in 
                  console.log(authResult);
                  const account = msalInstance.getActiveAccount();
                  
                  if(!account){
                    // redirect anonymous user to login page 
                    msalInstance.loginRedirect(loginRequest);
                  }else{
                    return account
                  }
                }).catch(err=>{
                  // TODO: Handle errors
                  console.log(err);
                });
                return accountinfo
        }
        
        
        
        
        loginUser().then((accountinfo)=>{
            app.config.globalProperties.$accountinfo_global_username = accountinfo.name
            app.config.globalProperties.$accountinfo_global_email = accountinfo.username
          })


    }
  }
  


