<template>
  <n-page-header subtitle="A place to control Finance data" @back="handleBack">
    <!-- <n-grid :cols="5">
      <n-gi>
        <n-statistic label="Episodes" value="125" />
      </n-gi>
      <n-gi>
        <n-statistic label="Guests" value="22" />
      </n-gi>
      <n-gi>
        <n-statistic label="Apologies" value="36" />
      </n-gi>
      <n-gi>
        <n-statistic label="Topics" value="83" />
      </n-gi>
      <n-gi>
        <n-statistic label="Reference Links" value="2,346" />
      </n-gi>
    </n-grid> -->
    <template #title>
      DTC-Ecommerce-Data Finance dashboard
    </template>
    <template #header>
      <!-- <n-breadcrumb>
        <n-breadcrumb-item>Podcast</n-breadcrumb-item>
        <n-breadcrumb-item>Best Collection</n-breadcrumb-item>
        <n-breadcrumb-item>Ultimate Best Collection</n-breadcrumb-item>
        <n-breadcrumb-item>Anyway.FM</n-breadcrumb-item>
      </n-breadcrumb> -->
    </template>
    <template #avatar>
      <n-avatar
        src="https://assets.dtc.beer/assets/DTC_LOGO_light.png"
      />
    </template>
    <template #extra>
      <n-space>
        <!-- <n-button>Refresh</n-button>
        <n-dropdown :options="options" placement="bottom-start">
          <n-button :bordered="false" style="padding: 0 4px">···</n-button>
        </n-dropdown> -->
      </n-space>
    </template>
    <!-- <template #footer>As of April 3, 2021</template> -->
  </n-page-header>
</template>
<script>
export default {
  name: 'Header',
  props: {
    username: String
  }
}
</script>