import { createApp } from 'vue'
import App from './App.vue'
import naive from "naive-ui"
import axios from 'axios'
import VueAxios from 'vue-axios'
import * as msal from "@azure/msal-browser";
import AzureAuth from "./repository/AzureAuth.js"

const app = createApp(App)
app.use(naive)
app.use(VueAxios, axios)
app.use(msal)
app.use(AzureAuth)
app.mount('#app')
